<template>
  <v-container id="application" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">
              <v-icon>mdi-file</v-icon>
              Application
            </v-toolbar-title>

            <v-spacer />

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto"
              label="Search"
              hide-details
              single-line
              style="max-width: 250px"
            />

            <v-btn fab color="success accent-2" to="/applications/new" icon>
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider />

          <v-card-text>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="applications"
              :search.sync="search"
              single-expand
              :expanded.sync="expanded"
              expand-icon="mdi-arrow-collapse-down"
              item-key="application_no"
              show-expand
              :loading="isBusy"
              loading-text="Loading... Please Wait"
              :sort-by="['join_date', 'book_date']"
              :sort-desc="[false, true]"
              multi-sort
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-card>
                    <v-list
                      dense
                      class="transparent"
                    >
                      <v-list-item>
                        <v-list-item-title>Switch Type</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.switch_type }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Plan</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.plan }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Postal Address</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.postal_address }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Medical Support</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.medical_support_flag }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Vulnerable</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.vulnerable_flag }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Daily Rate</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.daily_rate }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Volume Rate</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.volume_rate }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Have Dog?</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.have_dog_flag }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Is New Customer ?</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.is_new }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Customer No</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.customer_no }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>First Name</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.first_name }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Middle Name</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.middle_name }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Last Name</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.last_name }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Email</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.email }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Mobile No</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.mobile }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Phone No</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.phone }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Date of Birth</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.dob }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Credit Check Method</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.credit_check_method }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Driver License 5a</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.driver_license_5a }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Driver License 5b</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.driver_license_5b }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Passport No</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.passport_no }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Passport Nationality</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.passport_nationality }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Passport Expiry</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.passport_expiry }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Other Identification</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.other_identification }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Agree Credit Check</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.agree_credit_check_flag }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Is Joint Account?</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.is_joint }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Joint Name</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.joint_name }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Joint Contact No</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.joint_contact }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Joint DOB</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.joint_dob }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Billing Cycle</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.billing_cycle }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Payment Method</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.payment_method }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Agree T & C</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.agree_tnc_flag }}
                        </v-list-item-subtitle>

                        <v-list-item-title>Agree Privacy Policy</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.agree_privacy_flag }}
                        </v-list-item-subtitle>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-title>Referral</v-list-item-title>
                        <v-list-item-subtitle class="text-left">
                          {{ item.referral }}
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>

                    <v-card-actions>
                      <v-btn
                        color="primary"
                        @click="editItem(item)"
                      >
                        Edit
                      </v-btn>
                      <v-btn
                        color="secondary"
                      >
                        Create Customer
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Application',

  data: () => ({
    isBusy: false,
    errorMessage: '',
    applications: [],
    search: '',
    headers: [
      { text: '# No', value: 'application_no' },
      { text: 'Name', value: 'name' },
      { text: 'Book Date', value: 'book_date' },
      { text: 'Type', value: 'account_type' },
      { text: 'ICP', value: 'icpnum' },
      { text: 'Address', value: 'physical_address' },
      { text: 'Status', value: 'status' }
    ],
    expanded: []
  }),

  async created () {
    this.getAapplications()
  },

  methods: {
    getAapplications () {
      return new Promise((resolve, reject) => {
        this.$http.get('/api/applications', { withCredentials: true })
          .then(response => {
            console.log(response)
            this.isBusy = false
            this.applications = response.data.data
            resolve(response)
          })
          .catch(error => {
            this.errorMessage = 'Failed to load data'
            reject(error)
          })
          .finally(() => { this.isBusy = false })
      })
    }
  }
}
</script>
